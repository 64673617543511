import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { SideNavbarRouteLayoutComponent } from './views/side-navbar-route-layout/side-navbar-route.layout.component';
import { LoggedInGuard, RoleGuard, RoleLoaderGuard, SeeAllTenantGuard, TenantLoaderGuard } from './modules/auth/guards';
import { ForbiddenPageComponent } from './modules/auth/views/forbidden-page/forbidden-page.component';
import { SeeAllTenantUnsupportedPageComponent } from './modules/auth/views/see-all-tenant-unsupported-page/see-all-tenant-unsupported-page.component';
import { single } from './shared/utils';
import { Role } from './shared/enums';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    component: SideNavbarRouteLayoutComponent,
    canActivate: [LoggedInGuard],
    canActivateChild: [RoleLoaderGuard, TenantLoaderGuard, RoleGuard, SeeAllTenantGuard],
    children: [
      {
        path: '',
        redirectTo: 'status',
        pathMatch: 'full',
        data: { virtualSeeAllTenantSupported: true },
      },
      {
        path: 'status',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/system-status/system-status.module').then(m => m.SystemStatusModule),
      },
      {
        path: 'hupx/order',
        loadChildren: () => import('./modules/hupx-order/hupx-order.module').then(m => m.HupxOrderModule),
      },
      {
        path: 'hupx/new-external-order',
        loadChildren: () =>
          import('./modules/hupx-order-external/hupx-order-external.module').then(m => m.HupxOrderExternalModule),
      },
      {
        path: 'hupx/order-validation',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/hupx-order-validation/hupx-order-validation.module').then(m => m.HupxOrderValidationModule),
      },
      {
        path: 'hupx/public-order',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/public-order/public-orders.module').then(m => m.PublicOrdersModule),
      },
      {
        path: 'hupx/history',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/trade-history/trade-history.module').then(m => m.TradeHistoryModule),
      },
      {
        path: 'meki',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/meki-sek-schedules/meki-sek-schedules.module').then(m => m.MekiSekSchedulesModule),
      },
      {
        path: 'meki',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/meki-repair-tool/meki-repair-tool.module').then(m => m.MekiRepairToolModule),
      },
      {
        path: 'hupx/reports',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'hupx-dam/limit-order',
        loadChildren: () => import('./modules/limit-order/limit-order.module').then(m => m.LimitOrderModule),
      },
      {
        path: 'hupx-dam/deadlines',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/hupx-dam-deadlines/hupx-deadlines.module').then(m => m.HupxDeadlinesModule),
      },
      {
        path: 'hupx-ida/deadlines',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/hupx-dam-deadlines/hupx-deadlines.module').then(m => m.HupxDeadlinesModule),
      },
      {
        path: 'hupx-dam/limit-order/readonly-limit-orders',
        loadChildren: () => import('./modules/limit-order/limit-order.module').then(m => m.LimitOrderModule),
      },
      {
        path: 'hupx-dam/allocation',
        loadChildren: () => import('./modules/allocation/allocation.module').then(m => m.AllocationModule),
      },
      {
        path: 'hupx-dam/tenant-position',
        data: { role: single(Role.HupxDamTenantPosition), virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/tenant-position/tenant-position.module').then(m => m.TenantPositionModule),
      },
      {
        path: 'hupx-dam/final-limit-order',
        data: { role: single(Role.HupxDamFinalLimitOrder), virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/final-limit-order/final-limit-order.module').then(m => m.FinalLimitOrderModule),
      },
      {
        path: 'hupx-dam/tenant-final-limit-order',
        data: { role: single(Role.HupxDamTenantLimitOrder) },
        loadChildren: () =>
          import('./modules/final-limit-order/final-limit-order.module').then(m => m.FinalLimitOrderModule),
      },
      {
        path: 'hupx-dam/admin/group-balance',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/hupx-dam-group-balance/group-balance.module').then(m => m.GroupBalanceModule),
      },
      {
        path: 'hupx-dam/sek-da-schedule',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/hupx-dam-sek-da-schedule/hupx-dam-sek-da-schedule.module').then(
            m => m.HupxDamSekDaScheduleModule
          ),
      },
      {
        path: 'hupx-ida/limit-order',
        loadChildren: () => import('./modules/limit-order/limit-order.module').then(m => m.LimitOrderModule),
      },
      {
        path: 'hupx-ida/limit-order/readonly-limit-orders',
        loadChildren: () => import('./modules/limit-order/limit-order.module').then(m => m.LimitOrderModule),
      },
      {
        path: 'hupx-ida/allocation',
        loadChildren: () => import('./modules/allocation/allocation.module').then(m => m.AllocationModule),
      },
      {
        path: 'hupx-ida/tenant-position',
        data: { role: single(Role.HupxIdaTenantPosition), virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/tenant-position/tenant-position.module').then(m => m.TenantPositionModule),
      },
      {
        path: 'hupx-ida/final-limit-order',
        data: { role: single(Role.HupxIdaFinalLimitOrder), virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/final-limit-order/final-limit-order.module').then(m => m.FinalLimitOrderModule),
      },
      {
        path: 'hupx-ida/tenant-final-limit-order',
        data: { role: single(Role.HupxIdaTenantLimitOrder), virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/final-limit-order/final-limit-order.module').then(m => m.FinalLimitOrderModule),
      },
      {
        path: 'forward-trades',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/forward-trade/forward-trade.module').then(m => m.ForwardTradeModule),
      },
      {
        path: 'admin/platform-api-tokens',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/platform-api-tokens/platform-api-tokens.module').then(m => m.PlatformApiTokensModule),
      },
      {
        path: 'admin/service-tokens',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/service-tokens/service-tokens.module').then(m => m.ServiceTokensModule),
      },
      {
        path: 'admin/configurations',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/configurations/configurations.module').then(m => m.ConfigurationsModule),
      },
      {
        path: 'admin/tenants',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/tenants/tenants.module').then(m => m.TenantsModule),
      },
      {
        path: 'admin/roles',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/role-assignment/role-assignment.module').then(m => m.RoleAssignmentModule),
      },
      {
        path: 'boss',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/boss/boss.module').then(m => m.BossModule),
      },
      {
        path: 'eia',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/eia-report-sender/eia.module').then(m => m.EiaModule),
      },
      {
        path: 'workflow-parameter',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () =>
          import('./modules/workflow-parameters/workflow-parameters.module').then(m => m.WorkflowParametersModule),
      },
      {
        path: 'support',
        data: { virtualSeeAllTenantSupported: true },
        loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
      },
      {
        path: 'forbidden',
        data: { virtualSeeAllTenantSupported: true },
        component: ForbiddenPageComponent,
      },
      {
        path: 'see-all-tenant-unsupported',
        data: { virtualSeeAllTenantSupported: true },
        component: SeeAllTenantUnsupportedPageComponent,
      },
      {
        path: '**',
        data: { virtualSeeAllTenantSupported: true },
        component: PageNotFoundComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      /** Don't perform initial navigation in iframes or popups. It is especially important for MSAL Library */
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledBlocking' : 'disabled', // Remove this line to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
