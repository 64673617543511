import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeHu from '@angular/common/locales/hu';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { AutocompleteCellType, NumericCellType, registerCellType, TextCellType } from 'handsontable/cellTypes';
import { DropdownCellType } from 'handsontable/cellTypes/dropdownType';
import { Autofill, CopyPaste, registerPlugin, UndoRedo, Comments, NestedHeaders } from 'handsontable/plugins';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { AuthModule } from './modules/auth/auth.module';
import { LayoutModule } from './modules/layout/layout.module';
import {
  AadService,
  AuthorizationService,
  ConfigurationService,
  DialogService,
  LimitsService,
  MasterDataService,
  OrderValidationService,
  PublicOrderBookService,
  ReportsHupxService,
  ScenarioService,
  TenantService,
  HupxService,
  ReportService,
} from './services';
import { AppErrorHandler } from './services/app-error-handler.service';
import { msalGuardConfigFactory } from './services/msal-guard-config.factory';
import { msalInstanceFactory } from './services/msal-instance.factory';
import { msalInterceptorConfigFactory } from './services/msal-interceptor-config.factory';
import { AppComponent } from './views/app/app.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { SideNavbarRouteLayoutComponent } from './views/side-navbar-route-layout/side-navbar-route.layout.component';
import { SharedModule } from './modules/shared/shared.module';
import { SimplebarAngularModule } from 'simplebar-angular';
import { LoggedInGuard } from './modules/auth/guards';

registerCellType(TextCellType);
registerCellType(AutocompleteCellType);
registerCellType(DropdownCellType);
registerCellType(NumericCellType);
registerPlugin(UndoRedo);
registerPlugin(Autofill);
registerPlugin(CopyPaste);
registerPlugin(Comments);
registerPlugin(NestedHeaders);

registerLocaleData(localeHu);

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, SideNavbarRouteLayoutComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    MsalModule,
    AuthModule,
    LayoutModule,
    SharedModule,
    SimplebarAngularModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: msalInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: msalGuardConfigFactory },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    MsalBroadcastService,
    MsalGuard,
    MsalService,
    OrderValidationService,
    PublicOrderBookService,
    TenantService,
    ScenarioService,
    MasterDataService,
    AadService,
    AuthorizationService,
    ConfigurationService,
    LimitsService,
    ReportsHupxService,
    HupxService,
    ReportService,
    LoggedInGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
